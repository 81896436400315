import React, { ChangeEvent } from 'react';
import '../style/viewer.css'
import CONFIG from "../res/config";

export interface DataItem {
  id: number,
  date: string;
  sender_id: string;
  user_input: string;
  intent: string;
  entities: any[];
  raw: any;
  is_marked: boolean;
}

type ViewProps = {
  data: DataItem[];
  onMarkerChange: (index: number, newMarkerValue: boolean) => void;
  setDomainSelection: (event:any) => void;
  domainSelection: string;
}

export default function Viewer(props: ViewProps) {
  const options: any[] = [];
  CONFIG.db.domains.forEach(function(domain) {
    options.push({value: domain, label: domain})
  });

  const handleMarkerChange = (index: number, newMarkerValue: boolean) => {
    props.onMarkerChange(index, newMarkerValue);
  };

  const handleDomainChange = (event:any) => {
    console.log(event.target.value)
    props.setDomainSelection(event.target.value)
  }

  return (
    <>
    <div>
      <h2>Data Table</h2>
      <select value={props.domainSelection} onChange={handleDomainChange}>
        <option value="" disabled>Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <table>
        <thead>
          <tr>
            <th>X</th>
            <th>Session</th>
            <th>Date</th>
            <th>User Input</th>
            <th>Intent</th>
            <th>Entities</th>
            <th>Raw</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item: DataItem, index: number) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={item.is_marked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onMarkerChange(index, e.target.checked)
                  }
                />
              </td>
              <td>{item.sender_id}</td>
              <td>{item.date}</td>
              <td>{item.user_input}</td>
              <td>{item.intent}</td>
              <td>
                  {item.entities.map((ent, index) => (
                    <div key={index}>
                      <p style={{ color: 'lightseagreen', margin: 0, float: "left",}}>{ent.entity}: </p>
                      <p style={{ color: 'darkorange', margin: 0 }}>{ent.value}</p>
                    </div>
                  ))}
              </td>
              <td><pre>{JSON.stringify(item.raw,null,2)}</pre></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};