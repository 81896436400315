import general_auth from "./general_auth_gen2"

export default {
  asr: {
    url: "https://stream.alphaspeech.de",
    /** 
     * rtcConf is of type RTCConfiguration:
     * {
     *    iceServers: [{urls:"...", credential:"...", username:"..."}]}
     * */
    rtcConf: {
      iceServers: [
        // {urls: "turn:turn.dev.alphaspeech.de", credential:"no", username:"no"}
        {urls: "turn:a.relay.metered.ca:443", credential:"y1PyHpi6B5t1ADo0", username:"08046b7d118c39a9cb0c050f"}
      ]
    },
    domainConfFormSpeech: {
        asr: {"domain": "de-DE_PI-RADS"},
        punctuation: {"enable": false},
        structure: {"enable": false},
        itn: {
            "enable": true,
            "convert_small_nums": true,
            "convert_datetime_structures": true,
            "convert_currency_structures": true,
            "convert_measurement_units": true
        },
        replacements: {
            "custom_replacements": {
                "T_Z_A": "TZa",
                "T_Z_P": "TZp",
                "P_Z_A": "PZa",
                "P_Z_P_L": "PZpl",
                "P_Z_P_M": "PZpm",
                "D_C_E": "DCE",
                "D_W_I": "DWI",
                "T 2": "T2",
                "P_S_A": "PSA"
            }
        }
    },
    domainConfFreeText: {
      asr: {"domain": "de-DE_GENERIC+PI-RADS_free-text"},
      punctuation: {"enable": true, "mode": "dictate"},
      formatting: {"enable": true},
      structure: {"enable": true},
      itn: {
          "enable": true,
          "convert_small_nums": true,
          "convert_datetime_structures": true,
          "convert_currency_structures": true,
          "convert_measurement_units": true
      },
      replacements: {
        "custom_replacements": {
            "T2 w": "T2w",
            "T 1 w": "T1w"
        }
    }
  },
    auth: general_auth
  },
  nlu: {
    auth: general_auth,
    url: "https://formsnlu.alphaspeech.de/v1/nlu/",
    nluConfig: {"domain": "pirads", "language": "de-DE"}
  }
}
