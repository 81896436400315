const categories = [
    "technology",
    "medicalHistory",
    "summary"
]

const templates: any = {
    "technology": [
        "1,5 Tesla (GE Explorer), 16-Kanal Bodyspule, comutergestützte Analyse, digitale BildarchivierungProstata: T2w koronar, transversal und sigittal (3mm), T1w transversal (5mm)Ergänzende Serie: Focus-Diffusionsbildgebung b=50, 500, 1000, 1500 (3mm)Weitere Sequenz: nach KM-Gabe i.v. DISCO-Perfusion mit zeitl. Auflösung von 7,5 s, T1w transversal FS (gesamtes Becken) Buscapan i.v.",
        "stood bet bound beyond friendly about forget claws full talk hang keep class body shot plan above increase pony long health arrange till love1,5 Tesla (GE Explorer), 16-Kanal Bodyspule, comutergestützte Analyse, digitale BildarchivierungProstata: T2w koronar, transversal und sigittal (3mm), T1w transversal (5mm)Ergänzende Serie: Focus-Diffusionsbildgebung b=50, 500, 1000, 1500 (3mm)Weitere Sequenz: nach KM-Gabe i.v. DISCO-Perfusion mit zeitl. Auflösung von 7,5 s, T1w transversal FS (gesamtes Becken) Buscapan i.v.",
        "duty identity require start spent treated tears positive breath indeed scale said excellent shoe chosen them determine also highway street police opposite flight newspaper"
    ],
    "medicalHistory": [
        "Verdacht auf Prostatatumor",
        "Abklärung unspezifischer Symptome"
    ],
    "summary": [
        "Periphere Zone:\nProstatakapsel und rektoprostatischer Winkel:\nSamenblasen:\nHarnblase:\nLymphknoten:\nOssäre Strukturen:\nSonstiges:\n"
    ]
}

function getTemplates(category: string): string[] {
    if (!categories.includes(category)) {
        console.error("Template category not found.");
        return [""]
    }
    var localTemplates = localStorage.getItem(category);
    if (localTemplates !== null) {
        let templates: string[] = JSON.parse(localTemplates);
        return templates.concat(templates);
    }
    return templates[category];
}

function saveAsTemplate(category: string, value: string) {
    if (!categories.includes(category)) {
        console.error("Template category not found.");
        return;
    }
    var localTemplates = localStorage.getItem(category);
    if (localTemplates !== null) {
        let templates: string[] = JSON.parse(localTemplates);
        templates.forEach((template: string) => {
            if (value === template) {
                return;
            }
        })
        templates.push(value);
        localStorage.setItem(category, JSON.stringify(templates));
    }
}

function deleteTemplate(category: string, value: string) {
    console.error("deleteTemplate: Not implemented yet...");
    // TODO
}

export {
    saveAsTemplate,
    getTemplates
}
