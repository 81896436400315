
function getUpdatedText(prevData, textAreaCursorPositionRef, newData) {
    const isPunctuation = (char: string) => /[.,!$%;:{}()"]/.test(char);

    let updatedData:string;
    let leftText:string;
    let rightText:string;

    if (prevData === "") {
        updatedData = newData;
        leftText = "";
        rightText = "";
    }
    else {
        leftText = prevData.slice(0, textAreaCursorPositionRef.current);
        rightText = prevData.slice(textAreaCursorPositionRef.current);
        //  add space to left or right of new text unless
        //  - there's already a space
        //  - the last character of newData is not space/punctuation, but the first character after newData is punctuation
        if (leftText.length > 0 &&
            leftText[leftText.length-1] !== ' ' &&  // there is not already a space on the left of the new text
            !(!isPunctuation(leftText[leftText.length-1]) && isPunctuation(newData[0]))) // the first character in new text is NOT punctuation while last character is not . e.g. "test" + ". Das wars." should NOT append with space. "test."+". Das wars." should though.
        {
            newData = ' ' + newData;
        }
        if (rightText.length > 0 &&
            rightText[0] !== ' ' && // there is not already a space at the end of new text
            !(!isPunctuation(newData[newData.length-1]) && isPunctuation(rightText[0]))) // There is not already punctuation following the new text, e.g. "test" + ". Das wars." should not append with space.
        {
            newData = newData + ' ';
        }

        updatedData = leftText + newData + rightText;
    }

    return updatedData
}

export { getUpdatedText };
