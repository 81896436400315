import React, { useEffect, useState, useRef } from 'react';
import { faRotateLeft, faEraser, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../css/drawableImage.css';

// Based on: https://img.ly/blog/how-to-draw-on-an-image-with-javascript/

function DrawableImage(props: any) {
    const color = 'blue';
    let size:number = 25;
    let image = new Image();
    const imgRef = useRef<HTMLImageElement>(image);
    const canvasRef = props.canvasRef;
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const isMouseDownRef = useRef(isMouseDown);
    const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
    let canvasContainerRef = useRef<HTMLDivElement | null>(null);

    const [isLocked, setIsLocked] = useState(false);
    const isLockedRef = useRef(isLocked);

    const [history, setHistory] = useState<string[]>([]);
    const [redoStack, setRedoStack] = useState<string[]>([]);
    const [initialState, setInitialState] = useState<string | null>(null);

    const minWidthDiv = 400;
    const maxWidthImage = 500;

    useEffect(() => {
        const canvasElement = canvasRef.current;
        if (canvasElement) {
            const ctx = canvasElement.getContext('2d');
            if (ctx) {
                setContext(ctx);

                // Load initial image on the canvas (simulating the "clear" state)
                image.src = props.imagePath; // Replace with your image path
                const handleImageLoad = () => {
                    const imageWidth = image['width'];
                    const imageHeight = image['height'];
                    const aspectRatio = imageHeight / imageWidth;
                    let containerWidth = document.getElementById("canvasContainer")?.clientWidth || window.innerWidth;
                    if (containerWidth > maxWidthImage) {containerWidth = maxWidthImage}
                    canvasElement.width = containerWidth - 25;
                    canvasElement.height = canvasElement.width * aspectRatio;

                    ctx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height);

                    const initialImageState = canvasElement.toDataURL();
                    setInitialState(initialImageState);
                    setHistory([initialImageState]);
                    enableDrawOnImage(image, canvasElement, ctx)
                };
                image.addEventListener('load', handleImageLoad);

                 return () => {
                    image.removeEventListener('load', handleImageLoad);
                };
            }
        }
    }, []);


    function enableDrawOnImage(image:any, canvasElement:any, ctx:any) {
        if (ctx !== null) {
            console.log("SETTING ON MOUSE DOWN EVENT")

            canvasElement.onmousedown = (e:any) => {
                if (!isLockedRef.current) {
                    setIsMouseDown(true);

                    const rect = canvasElement.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;

                    setCirclePosition({ x, y });
                }
            };
            canvasElement.onmousemove = (e:any) => {
                if (isMouseDownRef.current && !isLockedRef.current) {
                    const rect = canvasElement.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;

                    setCirclePosition({ x, y });
                }
            }
            canvasElement.onmouseup = (e:any) => {
                if (!isLockedRef.current) {
                    setIsMouseDown(false);
                    const rect = canvasElement.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;

                    ctx.beginPath();
                    ctx.arc(x, y, size / 2, 0, Math.PI * 2, true);
                    ctx.fillStyle = color;
                    ctx.fill();

                    ctx.closePath();

                    const currentImageState = canvasElement.toDataURL();
                    setHistory((prevHistory) => [...prevHistory, currentImageState]);
                    setRedoStack([]);
                }
            }
        }
    }

    function clear() {
        const canvasElement = canvasRef.current;
        if (context && canvasElement) {
            const initialState = history[0];

            const imageData = new Image();
            imageData.src = initialState;
            imageData.onload = () => {
                if (context && canvasRef.current) {
                    context.drawImage(imageData, 0, 0); // Draw the redo state
                }
                const currentImageState = canvasElement.toDataURL();
                setHistory((prevHistory) => [...prevHistory, currentImageState]);
                setRedoStack([]);
            };
        }
    }
    function undo() {
        if (history.length > 1 && context !== null) {
            const newHistory = [...history];
            const previousState = newHistory.pop()!; // Remove the last state
            setHistory(newHistory);
            setRedoStack((prevRedo) => [previousState, ...prevRedo]); // Move last state to redo stack

            const imageData = new Image();
            imageData.src = newHistory[newHistory.length - 1]; // Load the previous state
            imageData.onload = () => {
                if (context && canvasRef.current) {
                    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas
                    context.drawImage(imageData, 0, 0); // Draw the previous state
                }
            };
        }
    }
    function redo() {
        if (redoStack.length > 0 && context !== null) {
            const newRedoStack = [...redoStack];
            const redoState = newRedoStack.shift()!; // Get the last undone state
            setRedoStack(newRedoStack);

            setHistory((prevHistory) => [...prevHistory, redoState]); // Move the redo state to history

            const imageData = new Image();
            imageData.src = redoState;
            imageData.onload = () => {
                if (context && canvasRef.current) {
                    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas
                    context.drawImage(imageData, 0, 0); // Draw the redo state
                }
            };
        }
    }

    const toggleLock = () => {
        console.log(isLocked)
        setIsLocked((prevIsLocked) => !prevIsLocked);
    };
    useEffect(() => {
        isLockedRef.current = isLocked;
    }, [isLocked]);
    useEffect(() => {
        isMouseDownRef.current = isMouseDown;
    }, [isMouseDown]);

    return (
        <div id="canvasContainer" style={{ position: 'relative', margin: "10px 0", minWidth: minWidthDiv+20+"px" }} className="col-sm-6">
            <div ref={canvasContainerRef} id="canvasContainer" style={{minWidth: minWidthDiv+"px" }}>
                <canvas
                    ref={canvasRef}
                    id="canvas"
                ></canvas>
            </div>
            {isMouseDown && (
                <div
                    style={{
                        position: 'absolute',
                        top: circlePosition.y - size/2, // TODO: Im not sure why this is the correct offset. it technically shouldnt be.
                        left: circlePosition.x,
                        width: size,
                        height: size,
                        padding: 0,
                        margin: 0,
                        borderRadius: '50%',
                        backgroundColor: color,
                        pointerEvents: 'none',
                        opacity: 0.6,
                    }}
                />
            )}
            <div style={{ display: 'flex', gap: '5px' }}>
                <button disabled={history.length <= 1} onClick={() => undo()}><FontAwesomeIcon icon={faRotateLeft}/></button>
                <button disabled={redoStack.length === 0} style={{ transform: 'scaleX(-1)' }} onClick={() => redo()}><FontAwesomeIcon icon={faRotateLeft}/></button>
                <button onClick={() => clear()}><FontAwesomeIcon icon={faEraser}/></button>
                <button onClick={toggleLock}>
                    {isLocked ? (
                        <FontAwesomeIcon icon={faLock} />
                    ) : (
                        <FontAwesomeIcon icon={faLockOpen} />
                    )}
                </button>
            </div>
        </div>
    )
}

export default DrawableImage;
