import { withJsonFormsControlProps } from '@jsonforms/react';
import { and, ControlProps, rankWith, schemaMatches, schemaTypeIs } from '@jsonforms/core';
import "../css/enum.css";
import { LesionAreaHeaderContext } from '../context/LesionAreaHeaderContext';

import { useState, useContext } from 'react';
import _ from 'lodash';

function Enum(props: ControlProps) {
    const [prevData, setPrevData] = useState(props.data);

    var enumId = Math.random();
    var inputs: JSX.Element[] = [];
    props.schema.enum?.forEach((e: string) => {
        var checked: boolean = props.data === e;
        var id = enumId + props.path + "/" + e;
        inputs.push(
            <input
                type="radio"
                className="btn-check"
                onChange={(e: any) => { props.handleChange(props.path, e.target.value) }}
                name={enumId + props.path}
                value={e}
                key={id}
                id={id}
                autoComplete="off"
                checked={checked}></input>
        );
        inputs.push(
            <label
                className="btn btn-light"
                key={id + "label"}
                style={{ "fontSize": "smaller" }}
                htmlFor={id}>{e}</label>
        );
    })

    const [isJiggling, setIsJiggling] = useState(false);
    const triggerJiggle = () => {
        setIsJiggling(true);
        setTimeout(() => setIsJiggling(false), 200); // Duration of the jiggle animation
    };

    if (!_.isEqual(prevData, props.data)) {
        if (props.data !== undefined && props.data !== '') {
            triggerJiggle();
            setPrevData(props.data);
        }
    }

    // useEffect(() => {
    //     if (props.data !== undefined && props.data !== '') {
    //         triggerJiggle();
    //     }
    // }, [props.data]);

    const handleInputChange = (event: any) => {
        const newValue = event.target.value;
        props.handleChange(props.path, parseFloat(newValue));
    };


    const { header } = useContext(LesionAreaHeaderContext);
    var headerEl;
    if (header === true && props.schema.hasOwnProperty('title')) {
        headerEl = (
            <p><b>{props.schema.title} |</b></p>
        );
    }
    return (
        <>
            <div className={props.path === "pirads" ? "lesionpirads" : ""}>
                {headerEl}
            </div>
            <div className='row'>
                <div className={`btn-group ${isJiggling ? 'jiggle' : ''}`}role="group" aria-label="Basic radio toggle button group">
                    {inputs}
                </div>
            </div>
        </>
    );
}

const EnumRenderer = withJsonFormsControlProps(Enum);
const EnumTester = rankWith(3, and(schemaTypeIs('string'), schemaMatches(schema => schema.hasOwnProperty('enum'))));

export {
    EnumRenderer,
    EnumTester
};
