import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from 'react';


function WrongValueWarning({id, isVisible, float="right", padding="0 15px 0 0"}: any) {
    const [marginLeft, setMarginLeft] = useState(0);
    const [marginTop, setMarginTop] = useState(0);

    return (
        <div
            title="Angegebener und berechneter Wert sind nicht konsistent."
            id={id}
            style={{
                float: float,
                padding: padding,
                visibility: isVisible ? "visible" : "hidden",
            }}
        >
            <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{ color: "#FF343B"}}
            />
        </div>
    )
}

export {
    WrongValueWarning
}
//, visibility: "hidden"