import React, { useRef, useContext, useEffect, useState } from 'react';
import { AppStateProvider, AppStateContext } from "../context/AppStateContext";
import "../css/highlight_in_textarea.css";
import _ from 'lodash';

interface DictateProps {
    header: string;
    divID: string;
    data: string;
    setData: React.Dispatch<React.SetStateAction<string>>;
    setAsrFocus: any;
    handleCursorPositionChange: any;
    children?: React.ReactNode;
};

const Dictate: React.FC<DictateProps> = (props) => {
    const [highlightedText, setHighlightedText] = useState("");
    const [prevState, setPrevState] = useState("");
    const [prevCursorPos, setPrevCursorPos] = useState(0);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    function handleChange (data: string) {
        props.setData(data);
        setPrevState(data);

        if (textAreaRef.current?.scrollHeight) {
            textAreaRef.current.scrollTop = textAreaRef.current?.scrollHeight;
        }
    };

    function handleFocusChange() {
        // reset cursor to end of text field
        textAreaRef.current?.setSelectionRange(props.data.length,props.data.length);
        props.handleCursorPositionChange(textAreaRef);
        // set ASR focus
        props.setAsrFocus(props.divID, false);
    }

    function handlePositionChange() {
        if (textAreaRef.current) {
            const newPos = textAreaRef.current.selectionStart;
            setPrevCursorPos(newPos);
        }
        props.handleCursorPositionChange(textAreaRef);
    }

    useEffect(() => { // this effect applies highlight to added text and scrolls to the end of the new text
        const text = props.data;
        if (prevState !== text) {
            const cursorPosBefore = prevCursorPos;
            const lengthBeforeUpdate = prevState.length;
            const lengthAddedText = text.length - lengthBeforeUpdate;
            const cursorPosAfter = cursorPosBefore+lengthAddedText;
            setPrevState(text);

            // scroll to where the cursor is
            textAreaRef.current?.setSelectionRange(cursorPosAfter, cursorPosAfter);
            textAreaRef.current?.blur()
            textAreaRef.current?.focus()

            // highlight via marking - could be prettier, but other method sometimes misaligns when text is too big
            textAreaRef.current?.setSelectionRange(cursorPosBefore, cursorPosAfter);

            // set current cursor again (blur() & focus() sets it to the very end of the text field)
            setTimeout(() => {
                textAreaRef.current?.setSelectionRange(cursorPosAfter, cursorPosAfter);
                handlePositionChange();
            }, 150);
        }
    }, [props.data]);

    return (
        <div id={props.divID} className="formDivDark">
            <div className="row">
                <div className="col-6 col-md-6">
                    <h2>{props.header}</h2>
                </div>
                    <div className="col-6 col-md-6" style={{ "flexDirection": "row-reverse", "display": "flex", "alignItems": "center" }}>
                        {props.children}
                    </div>
                </div>

            <div className="highlight_container">
                <div className="backdrop"></div>
                <textarea
                    ref={textAreaRef}
                    id={props.divID + "Textarea"}
                    className='highlight_textarea'
                    value={props.data}
                    onChange={(e: any) => { handleChange(e.target.value) }}
                    onFocus={() => { handleFocusChange(); }}
                    onClick={() => { handlePositionChange(); }}
                    onKeyUp={() => { handlePositionChange(); }}
                ></textarea>
            </div>

        </div>
    );
}

export default Dictate;
