import React, { useEffect, useContext, useRef, useState } from 'react';
import { saveAsTemplate, getTemplates } from "./localTemplateService";
import Dictate from './Dictate';
import { faPenToSquare, faFloppyDisk, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO:
/**
 * - editierte Vorlagen speichern in localStorage
 * - evtl. Titel für Vorlagen
 */

interface TemplateProps {
    template: string;
    key: number;
    setTextFieldText: React.Dispatch<React.SetStateAction<string>>;
};

const Template: React.FC<TemplateProps> = (props) => {
    const textAreaRef = useRef<HTMLTextAreaElement|null>(null);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className="row" key={props.key} style={{ minWidth: "400px"}}>
            <div className="col-10">
                <textarea
                    ref={textAreaRef}
                    disabled={!isEditing}
                    onClick={() => {
                        if (!isEditing) {
                            props.setTextFieldText(textAreaRef.current?.value ?? "")
                        }
                    }}
                    style={
                        {
                            "marginTop": "0",
                            "width": "100%",
                            "height": "calc(300px - 9vw)",
                            "resize": "none",
                            "border": "2px solid lightgray",
                            "borderRadius": "5px"
                        }
                    }>{props.template}</textarea>
            </div>
            <div className="col-1">
                <div className="mb-1">
                    <button
                        title={isEditing ? "Template speichern" : "Template ändern"}
                        className="btn btn-outline-primary"
                        onClick={() => { setIsEditing(!isEditing); }}>
                        <FontAwesomeIcon icon={isEditing ? faFloppyDisk : faPenToSquare} />
                    </button>
                </div>
                <div className="mb-1">
                    <button
                        title="Template importieren"
                        className="btn btn-outline-primary"
                        onClick={() => {
                            props.setTextFieldText(textAreaRef.current?.value ?? "")
                        }}
                        data-bs-dismiss="modal">
                        <FontAwesomeIcon icon={faFileImport} />
                    </button>
                </div>
            </div>
        </div>
    )
}

function SelectTemplate({ category, setState, modalId, templates, setTemplates }: any) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Vorlagen</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            {templates.map((template: any, index: number) => (
                                <Template
                                    template={template}
                                    key={index}
                                    setTextFieldText={setState}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface DictateWithTemplateProps {
    header: string;
    divID: string;
    category: string;
    state: string;
    setState: any;
    setAsrFocus: any;
    handleCursorPositionChange: any;
};

const DictateWithTemplate: React.FC<DictateWithTemplateProps> = (props) => {
    const modalId = "modal_" + props.category;
    const [templates, setTemplates] = useState<string[]>(getTemplates(props.category));

    return (
        <>
            <SelectTemplate modalId={modalId} category={props.category} setState={props.setState} templates={templates} setTemplates={setTemplates}></SelectTemplate>
            <Dictate
                header={props.header}
                divID={props.divID}
                data={props.state}
                setData={props.setState}
                setAsrFocus={props.setAsrFocus}
                handleCursorPositionChange={props.handleCursorPositionChange}>
                        <div>
                            <button
                                className="btn btn-secondary"
                                style={{ "height": "fit-content", "width": "-webkit-fill-available", "marginLeft": "10px", "fontSize": "calc(0.3rem + .5vw)" }}
                                onClick={() => {
                                    console.log("Vorlage speichern:", props.state)
                                    saveAsTemplate(props.category, props.state);
                                    setTemplates(prevTemplates => [...prevTemplates, props.state]);
                                }}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target={"#" + modalId}
                            > ALS VORLAGE SPEICHERN
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-secondary"
                                style={{ "height": "fit-content", "width": "-webkit-fill-available", "marginLeft": "10px", "fontSize": "calc(0.3rem + .5vw)" }}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target={"#" + modalId}
                            >VORLAGE LADEN</button>

                </div>
            </Dictate>
        </>
    );
}

export default DictateWithTemplate;
