import { withJsonFormsControlProps } from '@jsonforms/react';
import { ControlProps, rankWith, schemaTypeIs } from '@jsonforms/core';
import { TextField, InputAdornment } from '@mui/material';
import { useState, useEffect } from 'react';

function ProstateNumber(props: ControlProps) {
    const [isJiggling, setIsJiggling] = useState(false);

    const unit = props.uischema?.options?.unit || '';
    const min = props.uischema?.options?.min || '';
    const max = props.uischema?.options?.max || '';
    const step = props.uischema?.options?.step || '';
    const headerEl = (
        <>{props.schema.title} |</>
    );

    const triggerJiggle = () => {
        setIsJiggling(true);
        setTimeout(() => setIsJiggling(false), 200); // Duration of the jiggle animation
    };

    useEffect(() => {
        if (props.data !== undefined && props.data !== '') {
            triggerJiggle();
        }
    }, [props.data]);

    return (
        <div className='form-group pb-3' style={{minWidth: "115px", maxWidth: "200px"}}>
            <label htmlFor={props.id}><b>{headerEl}</b></label>
            <TextField
                id={props.id}
                className={`form-control ${isJiggling ? 'jiggle' : ''}`}
                type='number'
                value={props.data || ''}
                onChange={
                        (e: any) => { props.handleChange(props.path, parseFloat(e.target.value)) }
                }
                InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                    inputProps: {
                      min: min,
                      max: max,
                      step: step
                    }
                }}
            />
        </div>
    )
}

const NumberRenderer = withJsonFormsControlProps(ProstateNumber);
const NumberTester = rankWith(3, schemaTypeIs('number'));

export {
    NumberRenderer,
    NumberTester
};
