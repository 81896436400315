function FormDiv({divID, title, children}: any) {
    return (
        <div id={divID} className='formDivLight'>
            <h3 style={{float: "left"}}>{title}</h3>
            {children}
        </div>
    );
}

export default FormDiv;
